import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import Layout from "../../components/Layout";
import styled from "styled-components";
import { Link, navigate } from "gatsby";

import BgImage1 from "../../img/news-bg.png";

const FormIndexPage = ({pageContext}) => {
  const { breadcrumb: { crumbs }} = pageContext;
  return(
  <Layout>
    <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="予約・お問い合わせ"  />
      <MidashiBg>
        <Midashi>予約・お問い合わせ</Midashi>
      </MidashiBg>
      <Button onClick={()=>{navigate("/form/stay")}}>宿泊予約</Button>
      <Button onClick={()=>{navigate("/form/nawate")}}>NAWATE見学</Button>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="予約・お問い合わせ"  />
      </Body>
  </Layout>
);
}

export default FormIndexPage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  text-align: center;
`;

const MidashiBg = styled.div`
  width: 100%;
  height: 15em;
  background-image: url(${BgImage1});
  padding: 5em 15vw 0 15vw;
  margin: 0 0 5em 0;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  text-align: center;
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 20pt;
`;

const Button = styled.button`
  background: #666666;
  width: 20vw;
  height: 10vh;
  margin: 5vh 0 10vh 0;
  border: none;
  border-radius: 10px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 14pt;
  color: #ffffff;

  &:hover {
    background: #f6f5f1;
    border: 1px #666666 solid;
    border-radius: 10px;
    color: #666666;
    pointer: cursor;
  }
`;
